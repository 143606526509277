import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import TimeRangePicker from "../common/TimeRangePicker";
import DateRangePicker from "../common/DateRangePicker";
import {
  StyledTextField,
  StyledButton,
  StyledSelect,
} from "../StyledComponents";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import ImageSelector from "../common/ImageSelector";
import VideoSelector from "../common/VideoSelector";
import MenuItem from "@mui/material/MenuItem";
import dayHoursStrings from "../utils/hourStrings";
import parseSchedule from "../utils/parseSchedule";
import { FormControlLabel, Switch } from "@mui/material";
import { usePositions } from "../hooks/useFetch";
import insertIntoSortedList from "../utils/insertIntoSortedList";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../utils/PhoneInputStyles.css";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { extractColors } from "extract-colors";
import MyRating from "../common/MyRating";

const descriptions = [
  {
    id: "sports",
    text: "Sports",
  },
  {
    id: "meetup",
    text: "Meetup",
  },
  {
    id: "party",
    text: "Party",
  },
  {
    id: "trip",
    text: "Trip",
  },
  {
    id: "musicEvent",
    text: "Music event",
  },
  {
    id: "festival",
    text: "Festival",
  },
];

export default function EditBigEventForm({ token, event }) {
  const [name, setName] = useState(event.name);
  const [category, setCategory] = useState(event.category || "");
  const [location, setLocation] = useState(
    event.location.el ?? event.location.en
  );
  const [phone, setPhone] = useState(event.phone);
  const [mapsUrl, setMapsUrl] = useState(
    `https://www.google.com/maps/search/?api=1&query=${event.query}&center=${event.latitude},${event.longitude}&zoom=15`
  );
  const [rating, setRating] = useState(event.rating);
  const [active, setActive] = useState(true);
  const [isPromoted, setIsPromoted] = useState(false);
  const [position, setPosition] = useState(event.position || "");
  const [showVideoFirst, setShowVideoFirst] = useState(event.showVideoFirst);

  const [dates, setDates] = useState({
    open: dayjs(event.startDate),
    close: dayjs(event.endDate),
  });

  const [monday, setMonday] = useState(parseSchedule(event.schedule[0]));
  const [tuesday, setTuesday] = useState(parseSchedule(event.schedule[1]));
  const [wednesday, setWednesday] = useState(parseSchedule(event.schedule[2]));
  const [thursday, setThursday] = useState(parseSchedule(event.schedule[3]));
  const [friday, setFriday] = useState(parseSchedule(event.schedule[4]));
  const [saturday, setSaturday] = useState(parseSchedule(event.schedule[5]));
  const [sunday, setSunday] = useState(parseSchedule(event.schedule[6]));
  const [applyToAllDays, setApplyToAllDays] = useState(false);

  useEffect(() => {
    if (applyToAllDays) {
      // Check which values are set on Monday and update all days accordingly
      setAllDays({ open: monday.open, close: monday.close });
    }
  }, [monday, applyToAllDays]);

  // Update all days' opening or closing hours based on Monday's values
  const setAllDays = ({ open, close }) => {
    if (open !== null) {
      setTuesday((prev) => ({ ...prev, open }));
      setWednesday((prev) => ({ ...prev, open }));
      setThursday((prev) => ({ ...prev, open }));
      setFriday((prev) => ({ ...prev, open }));
      setSaturday((prev) => ({ ...prev, open }));
      setSunday((prev) => ({ ...prev, open }));
    }
    if (close !== null) {
      setTuesday((prev) => ({ ...prev, close }));
      setWednesday((prev) => ({ ...prev, close }));
      setThursday((prev) => ({ ...prev, close }));
      setFriday((prev) => ({ ...prev, close }));
      setSaturday((prev) => ({ ...prev, close }));
      setSunday((prev) => ({ ...prev, close }));
    }
  };

  const handleToggleChange = (event) => {
    const checked = event.target.checked;
    setApplyToAllDays(checked);
    if (checked) {
      setAllDays({ open: monday.open, close: monday.close });
    }
  };

  const [images, setImages] = useState([]);
  const [mainColor, setMainColor] = useState(null);
  const [mapScreenshot, setMapScreenshot] = useState([]);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoFile, setVideoFile] = useState();

  const beforeDelete = () => {
    // Set showVideoFirst to false when empty video
    setShowVideoFirst(false);
  };

  const extractDominantColor = async (image) => {
    const extractedColors = await extractColors(image.data_url);
    if (extractedColors.length > 0) {
      const dominantColor = extractedColors[0].hex;
      setMainColor(dominantColor);
    }
  };

  const [vidsChanged, setVidChanged] = useState(false);
  const [urlChanged, setUrlChanged] = useState(false);
  const [mapchanged, setMapChanged] = useState(false);
  const [picsChanged, setPicsChanged] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  const positions = usePositions("big_events", token);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (images.length > 0) {
      extractDominantColor(images[0]);
    }
  }, [images]);

  const navigate = useNavigate();

  const editEvent = useMutation(
    () => {
      const headers = {
        Accept: "*/*",
        token: token,
        "Content-Type": "multipart/form-data",
      };

      const formData = new FormData();

      if (picsChanged) {
        images.forEach((image, i) => {
          formData.append("pics", image.file);
        });
      }

      if (vidsChanged) formData.append("vids", videoFile);

      if (mapchanged) formData.append("mapscreen", mapScreenshot[0].file);

      formData.append(
        "data",
        JSON.stringify({
          name,
          location: location,
          phone: phone ? phone : null,
          rating: rating ? rating : null,
          ...(urlChanged ? { url: mapsUrl } : {}),
          active,
          isPromoted,
          category,
          position: position ? position : null,
          showVideoFirst,
          vidsChanged,
          startDate: dates.open.format("YYYY-MM-DD"),
          endDate: dates.close.format("YYYY-MM-DD"),
          mainColor,
          ...dayHoursStrings(monday, "Mon"),
          ...dayHoursStrings(tuesday, "Tue"),
          ...dayHoursStrings(wednesday, "Wed"),
          ...dayHoursStrings(thursday, "Thu"),
          ...dayHoursStrings(friday, "Fri"),
          ...dayHoursStrings(saturday, "Sat"),
          ...dayHoursStrings(sunday, "Sun"),
        })
      );

      return axios.put(
        `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/big_events/edit_big_event/${event.bigEventId}`,
        formData,
        { headers }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "big_events-get",
          ["get-positions", "big_events"],
        ]);
        setShowSuccess(true);
      },
    }
  );

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleMapsUrlChange = (event) => {
    setUrlChanged(true);
    setMapsUrl(event.target.value);
  };

  const handleRatingChanged = (event, newRating) => {
    setRating(newRating);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccess(false);
    navigate(-1);
  };

  const handleActiveChange = (event) => {
    if (!event.target.checked) setPosition("");
    setActive(event.target.checked);
  };

  const handlePromotedChange = (event) => {
    setIsPromoted(event.target.checked);
  };

  const handleVideoFirstChanged = (event) => {
    if ((videoSrc && event.target.checked) || !event.target.checked)
      setShowVideoFirst(event.target.checked);
  };

  const timeRangePickers = [
    <TimeRangePicker
      value={monday}
      setValue={setMonday}
      day="Monday"
      key="Monday"
    />,
    <TimeRangePicker
      value={tuesday}
      setValue={setTuesday}
      day="Tuesday"
      key="Tuesday"
    />,
    <TimeRangePicker
      value={wednesday}
      setValue={setWednesday}
      day="Wednesday"
      key="Wednesday"
    />,
    <TimeRangePicker
      value={thursday}
      setValue={setThursday}
      day="Thursday"
      key="Thursday"
    />,
    <TimeRangePicker
      value={friday}
      setValue={setFriday}
      day="Friday"
      key="Friday"
    />,
    <TimeRangePicker
      value={saturday}
      setValue={setSaturday}
      day="Saturday"
      key="Saturday"
    />,
    <TimeRangePicker
      value={sunday}
      setValue={setSunday}
      day="Sunday"
      key="Sunday"
    />,
  ];

  const showPickers = () => {
    if (!dates.close || !dates.open || dates.close.diff(dates.open, "day") >= 6)
      return timeRangePickers;
    else {
      const startIndex = Math.min(dates.open.$W, dates.close.$W) - 1;
      const endIndex = Math.max(dates.open.$W, dates.close.$W);
      return timeRangePickers.slice(startIndex, endIndex);
    }
  };

  useEffect(() => {
    // Only proceed if `event.mapscreen` and `token` are defined.
    if (event.mapscreen && token) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/big_events/file/${event.mapscreen}`,
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "*/*",
              token: token,
            },
          }
        )
        .then((res) => {
          // Only set mapScreenshot if it is currently empty
          if (!mapScreenshot.length) {
            const blob = new Blob([res.data], {
              type: "image/jpeg",
            });
            console.log("blob: ", blob);
            const objectURL = URL.createObjectURL(blob);
            const file = new File([blob], event.mapscreen, {
              lastModified: Date.now(),
            });
            setMapScreenshot([{ data_url: objectURL, file: file }]);
          }
        })
        .catch((error) => {
          console.error("Error fetching map screen image:", error);
        });
    }
  }, [event.mapscreen, mapScreenshot.length, token]);

  useEffect(() => {
    if (event.videos.length) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/big_events/file/${event.videos[0]}`,
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "*/*",
              token: token,
            },
          }
        )
        .then((res) => {
          if (!videoSrc) {
            const blob = new Blob([res.data], {
              type: "file",
            });
            console.log("blob: ", blob);
            const objectURL = URL.createObjectURL(blob);
            setVideoSrc(objectURL);
          }
        })
        .catch((error) => {
          console.error("Error fetching video:", error);
        });
    }
  }, [event.videos, token, videoSrc]);

  useEffect(() => {
    const fetchPics = async (image) => {
      const lst = await Promise.all(
        event.photos.map(async (image) => {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/big_events/file/${image}`,
            {
              responseType: "arraybuffer",
              headers: {
                Accept: "*/*",
                token: token,
              },
            }
          );

          const blob = new Blob([res.data], {
            type: "image/jpeg",
          });
          console.log("blob: ", blob);
          const objectURL = URL.createObjectURL(blob);
          const file = new File([blob], image, { lastModified: Date.now() });
          console.log(file);
          return { data_url: objectURL, file: file };
        })
      );

      setImages(lst);
    };

    fetchPics();
  }, [event.photos, token]);

  console.log(dates.open);
  console.log(typeof dates.open);

  return (
    <Stack spacing={3} alignItems="center">
      <Stack
        spacing={13}
        direction="row"
        justifyContent="center"
        sx={{ paddingTop: 5 }}
      >
        <Stack spacing={3} direction="column" alignItems="left">
          <Typography
            variant="h1"
            align="center"
            fontSize={22}
            fontFamily="Trueno Light"
          >
            1. Main Info
          </Typography>
          <StyledTextField
            label="Big Event Title"
            placeholder="Enter Big Event name"
            onChange={handleNameChange}
            value={name}
            fullWidth
            inputProps={{ maxLength: 45 }}
          />
          <StyledSelect
            value={category}
            variant="outlined"
            onChange={handleCategoryChange}
            fullWidth
            displayEmpty
            renderValue={(selected) => {
              if (selected === "") {
                return <em>Choose Big Event category</em>;
              }
              return selected;
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {descriptions.map((desc) => (
              <MenuItem value={desc.id} key={desc.id}>
                {desc.text}
              </MenuItem>
            ))}
          </StyledSelect>
          <StyledTextField
            label="Big Event Location"
            placeholder="Enter Big Event location"
            onChange={handleLocationChange}
            value={location}
            fullWidth
            inputProps={{ maxLength: 45 }}
          />
          <StyledTextField
            label="Google Maps Link"
            placeholder="Enter Google Maps link"
            onChange={handleMapsUrlChange}
            value={mapsUrl}
            fullWidth
          />
          <PhoneInput
            className="phone-input"
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
            defaultCountry="GR"
            international
            countryCallingCodeEditable={false}
          />
          <Box
            sx={{
              border: "1px solid #1EABF1",
              borderRadius: "4px",
              padding: 2,
              width: "100%",
              maxWidth: 600,
              boxShadow: 1,
            }}
          >
            <Typography
              variant="h1"
              align="left"
              fontSize={20}
              fontFamily="Trueno Light"
              sx={{
                marginBottom: 2,
                marginLeft: 2,
                fontWeight: "bold",
                color: "#1EABF1",
              }}
            >
              Admin Only
            </Typography>
            <StyledSelect
              fullWidth
              displayEmpty
              renderValue={(selected) => {
                if (selected === "") {
                  return <em>Position in the App</em>;
                }
                return selected;
              }}
              value={position}
              variant="outlined"
              onChange={handlePositionChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {active ? (
                positions.isLoading ? (
                  <CircularProgress sx={{ color: "#1EABF1" }} />
                ) : positions.isError ? (
                  <h2>{positions.error.message}</h2>
                ) : (
                  insertIntoSortedList(positions.data, event.position).map(
                    (pos) => (
                      <MenuItem value={pos} key={pos}>
                        {pos}
                      </MenuItem>
                    )
                  )
                )
              ) : null}
            </StyledSelect>
            <MyRating
              handleRatingChanged={handleRatingChanged}
              rating={rating}
              setRating={setRating}
              sx={{ marginTop: 2 }}
            />
            <FormControlLabel
              control={
                <Switch checked={active} onChange={handleActiveChange} />
              }
              label="Active Big Event"
              sx={{
                marginTop: 1,
                fontFamily: "Trueno Light",
                "& .MuiFormControlLabel-label": {
                  fontFamily: "Trueno Light",
                },
              }}
            />
            <FormControlLabel
              control={
                <Switch checked={isPromoted} onChange={handlePromotedChange} />
              }
              label="Promoted Big Event"
              sx={{
                marginTop: 1,
                fontFamily: "Trueno Light",
                "& .MuiFormControlLabel-label": {
                  fontFamily: "Trueno Light",
                },
              }}
            />
          </Box>
        </Stack>
        <Stack
          spacing={4}
          alignItems="center"
          marginTop={4}
          marginBottom={2}
          paddingInline={4}
        >
          <Typography
            variant="h1"
            align="center"
            fontSize={22}
            fontFamily="Trueno Light"
          >
            2. Select your weekly working hours
          </Typography>
          <Stack
            width={550}
            direction="column"
            alignItems="flex-start"
            spacing={1}
          >
            <ul style={{ paddingLeft: 20, listStyleType: "disc", margin: 0 }}>
              <li>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#555",
                    fontFamily: "Trueno Light",
                  }}
                >
                  You can only select times in increments of 5 minutes.
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#555",
                    fontFamily: "Trueno Light",
                  }}
                >
                  In case of non-operating days, leave hours empty.
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#555",
                    fontFamily: "Trueno Light",
                  }}
                >
                  In case of one day's working hours extending to the next day,
                  fill in the closing time of the next day in the closing time
                  field of the current.
                </Typography>
              </li>
            </ul>
          </Stack>
          <Stack
            spacing={2.5}
            direction="column"
            alignItems="center"
            width={600}
          >
            <Stack
              direction="row"
              alignItems="left"
              spacing={0}
              width="95%"
              justifyContent="start"
            >
              <DateRangePicker value={dates} setValue={setDates} />
            </Stack>
            <Stack
              direction="row"
              alignItems="flex-start"
              spacing={2}
              width="90%"
              justifyContent="flex-start"
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={applyToAllDays}
                    onChange={handleToggleChange}
                  />
                }
                label="Apply Monday's hours to all days"
                sx={{
                  fontSize: 14,
                  textAlign: "left",
                  fontFamily: "Trueno Light",
                  "& .MuiFormControlLabel-label": {
                    fontFamily: "Trueno Light",
                  },
                }}
              />
            </Stack>
            <br />
            {showPickers()}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{ width: "100%", padding: 2, boxSizing: "border-box" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: 2,
            padding: 2,
          }}
        >
          <Box flex={1}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                minHeight: "400px",
                position: "relative",
              }}
            >
              <Typography
                variant="h6"
                fontFamily="Trueno Light"
                sx={{ marginBottom: 1 }}
              >
                3. Upload Images
              </Typography>
              <Typography
                sx={{
                  marginBottom: 2,
                  marginX: 4,
                  fontFamily: "Trueno Light",
                  color: "#666",
                  textAlign: "center",
                  fontSize: 14,
                }}
              >
                Add up to 5 images in the order you want them to appear on Join,
                1-1 Aspect Ratio and up to 2Mb per image.
                <br />
                <strong>Supported files: .jpeg, .jpg, .png, .heic</strong>
              </Typography>
              <ImageSelector
                images={images}
                setImages={(updatedImages) => {
                  setImages(updatedImages);
                  setPicsChanged(true);
                }}
                maxNumber={5}
                setDidChange={setPicsChanged}
                description="images"
              />
            </Paper>
          </Box>
          <Box flex={1}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                minHeight: "300px",
                position: "relative",
              }}
            >
              <Typography
                variant="h6"
                fontFamily="Trueno Light"
                sx={{ marginBottom: 1 }}
              >
                4. Upload Map Screenshot
              </Typography>
              <Typography
                sx={{
                  marginBottom: 2,
                  marginX: 4,
                  fontFamily: "Trueno Light",
                  color: "#666",
                  textAlign: "center",
                  fontSize: 14,
                }}
              >
                Add one screenshot taken from Google Maps, scale: 2000 feet/500
                feet, up to 2 MB, trimmed and clean from other elements, showing
                your location about one third from the top, centered.
                <br />
                <strong>Supported files: .jpeg, .jpg, .png, .heic</strong>
              </Typography>
              <ImageSelector
                images={mapScreenshot}
                setImages={setMapScreenshot}
                maxNumber={1}
                setDidChange={setMapChanged}
                description="map"
              />
            </Paper>
          </Box>
          <Box flex={1}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
                minHeight: "300px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  variant="h6"
                  fontFamily="Trueno Light"
                  sx={{ marginBottom: 1, textAlign: "center" }}
                >
                  5. Upload One Video{" "}
                  <span style={{ fontSize: "0.65em" }}>(optional)</span>
                </Typography>
                <Typography
                  sx={{
                    marginBottom: 2,
                    marginX: 4,
                    fontFamily: "Trueno Light",
                    color: "#666",
                    textAlign: "center",
                    fontSize: 14,
                  }}
                >
                  Add one video, 1-1 Aspect Ratio and up to 10Mb. The video will
                  show before or after the photos.
                  <br />
                  <strong>Supported files: .mov, .mp4</strong>
                </Typography>
                <VideoSelector
                  width={400}
                  height={300}
                  source={videoSrc}
                  setSource={setVideoSrc}
                  setVideoFile={setVideoFile}
                  beforeDelete={beforeDelete}
                  setVidChanged={setVidChanged}
                />
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={showVideoFirst}
                    onChange={handleVideoFirstChanged}
                  />
                }
                label="Show video before pictures?"
                sx={{
                  fontFamily: "Trueno Light",
                  "& .MuiFormControlLabel-label": {
                    fontFamily: "Trueno Light",
                  },
                }}
              />
            </Paper>
          </Box>
        </Box>
      </Stack>
      <Divider
        sx={{
          width: "85%",
          backgroundColor: "rgba(30, 171, 241, 0.2)",
          height: "1px",
        }}
      />
      {editEvent.isLoading ? (
        <CircularProgress sx={{ color: "#1EABF1" }} />
      ) : (
        <StyledButton
          variant="contained"
          size="large"
          width="30%"
          disabled={
            !name ||
            !location ||
            !category ||
            !mapsUrl ||
            !images.length ||
            !mapScreenshot.length ||
            (!Object.values(monday).every((x) => x !== null) &&
              !Object.values(tuesday).every((x) => x !== null) &&
              !Object.values(wednesday).every((x) => x !== null) &&
              !Object.values(thursday).every((x) => x !== null) &&
              !Object.values(friday).every((x) => x !== null) &&
              !Object.values(saturday).every((x) => x !== null) &&
              !Object.values(sunday).every((x) => x !== null))
          }
          onClick={() => editEvent.mutate()}
          myColor="#1EABF1"
          hoverColor="#187DDA"
        >
          Save Changes
        </StyledButton>
      )}
      <Snackbar
        open={editEvent.isError}
        autoHideDuration={2000}
        onClose={() => editEvent.reset()}
      >
        <Alert
          onClose={() => editEvent.reset()}
          severity="error"
          sx={{ width: "100%" }}
        >
          An error occured!
        </Alert>
      </Snackbar>
      <Snackbar
        open={showSuccess}
        autoHideDuration={2000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Changes saved successfully!
        </Alert>
      </Snackbar>
    </Stack>
  );
}
