import React, { useState, useEffect } from "react";
import logo from "../joinlogo.png";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "@mui/material/Slider";
import TimeRangePicker from "../common/TimeRangePicker";
import {
  StyledTextField,
  StyledButton,
  StyledSelect,
} from "../StyledComponents";
import ImageSelector from "../common/ImageSelector";
import MyRating from "../common/MyRating";
import VideoSelector from "../common/VideoSelector";
import MenuItem from "@mui/material/MenuItem";
import { useMutation, useQueryClient } from "react-query";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import dayHoursStrings from "../utils/hourStrings";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { usePositions } from "../hooks/useFetch";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../utils/PhoneInputStyles.css";
import { useNavigate } from "react-router-dom";
import { extractColors } from "extract-colors";


const marks = [
  {
    value: 1,
    label: "€",
  },
  {
    value: 2,
    label: "€€",
  },
  {
    value: 3,
    label: "€€€",
  },
  {
    value: 4,
    label: "€€€€",
  },
];

const descriptions = [
  {
    id: "nightClub",
    text: "Night Club",
  },
  {
    id: "bar",
    text: "Bar",
  },
  {
    id: "allDay",
    text: "All day Cafe-Bar-Restaurant",
  },
  {
    id: "beachBar",
    text: "Beach Bar",
  },
  {
    id: "restaurant",
    text: "Restaurant",
  },
  {
    id: "liveMusic",
    text: "Live music event",
  },
  {
    id: "pub",
    text: "Pub",
  },
  {
    id: "cinema",
    text: "Cinema theatre",
  },
  {
    id: "sportsClub",
    text: "Sports Club",
  },
  {
    id: "arcade",
    text: "Arcade & Entertainment",
  },
  {
    id: "other",
    text: "Other",
  },
];

export default function AddPlace({ token }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [mapsUrl, setMapsUrl] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [rating, setRating] = useState(0);
  const [active, setActive] = useState(true);
  const [isPromoted, setIsPromoted] = useState(false);
  const [position, setPosition] = useState("");
  const [showVideoFirst, setShowVideoFirst] = useState(false);

  const [monday, setMonday] = useState({ open: null, close: null });
  const [tuesday, setTuesday] = useState({ open: null, close: null });
  const [wednesday, setWednesday] = useState({ open: null, close: null });
  const [thursday, setThursday] = useState({ open: null, close: null });
  const [friday, setFriday] = useState({ open: null, close: null });
  const [saturday, setSaturday] = useState({ open: null, close: null });
  const [sunday, setSunday] = useState({ open: null, close: null });
  const [applyToAllDays, setApplyToAllDays] = useState(false);

  useEffect(() => {
    if (applyToAllDays) {
      // Check which values are set on Monday and update all days accordingly
      setAllDays({ open: monday.open, close: monday.close });
    }
  }, [monday, applyToAllDays]);

  // Update all days' opening or closing hours based on Monday's values
  const setAllDays = ({ open, close }) => {
    if (open !== null) {
      setTuesday((prev) => ({ ...prev, open }));
      setWednesday((prev) => ({ ...prev, open }));
      setThursday((prev) => ({ ...prev, open }));
      setFriday((prev) => ({ ...prev, open }));
      setSaturday((prev) => ({ ...prev, open }));
      setSunday((prev) => ({ ...prev, open }));
    }
    if (close !== null) {
      setTuesday((prev) => ({ ...prev, close }));
      setWednesday((prev) => ({ ...prev, close }));
      setThursday((prev) => ({ ...prev, close }));
      setFriday((prev) => ({ ...prev, close }));
      setSaturday((prev) => ({ ...prev, close }));
      setSunday((prev) => ({ ...prev, close }));
    }
  };

  const handleToggleChange = (event) => {
    const checked = event.target.checked;
    setApplyToAllDays(checked);
    if (checked) {
      setAllDays({ open: monday.open, close: monday.close });
    }
  };

  const [images, setImages] = useState([]);
  const [mainColor, setMainColor] = useState();
  const [mapScreenshot, setMapScreenshot] = useState([]);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoFile, setVideoFile] = useState();

  const beforeDelete = () => {
    // Set showVideoFirst to false when empty video
    setShowVideoFirst(false);
  };

  const extractDominantColor = async (image) => {
    const extractedColors = await extractColors(image.data_url);
    if (extractedColors.length > 0) {
      const dominantColor = extractedColors[0].hex;
      setMainColor(dominantColor);
    }
  };

  const [showSuccess, setShowSuccess] = useState(false);

  const positions = usePositions("places", token.token);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (images.length > 0) {
      extractDominantColor(images[0]);
    }
  }, [images]);

  const addPlace = useMutation(
    () => {
      console.log(token.token);

      const headers = {
        Accept: "*/*",
        token: token.token,
        "Content-Type": "multipart/form-data",
      };

      const formData = new FormData();

      images.forEach((image, i) => {
        formData.append("pics", image.file);
      });

      formData.append("vids", videoFile);
      formData.append("mapscreen", mapScreenshot[0].file);

      formData.append(
        "data",
        JSON.stringify({
          name,
          address: location,
          price: priceRange ? priceRange : null,
          phone: phone ? phone : null,
          rating: rating ? rating : null,
          url: mapsUrl,
          active,
          isPromoted,
          position: position ? position : null,
          showVideoFirst,
          description,
          mainColor,
          ...dayHoursStrings(monday, "Mon"),
          ...dayHoursStrings(tuesday, "Tue"),
          ...dayHoursStrings(wednesday, "Wed"),
          ...dayHoursStrings(thursday, "Thu"),
          ...dayHoursStrings(friday, "Fri"),
          ...dayHoursStrings(saturday, "Sat"),
          ...dayHoursStrings(sunday, "Sun"),
        }),
      );

      return axios.post(
        `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/places/create_place`,
        formData,
        { headers },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "places-get",
          ["get-positions", "places"],
        ]);
        setShowSuccess(true);
        setName("");
        setDescription("");
        setLocation("");
        setPhone("");
        setMapsUrl("");
        setRating(0);
        setPriceRange("");
        setActive(true);
        setPosition("");
        setShowVideoFirst(false);
        setIsPromoted(false);
        setMonday({ open: null, close: null });
        setTuesday({ open: null, close: null });
        setWednesday({ open: null, close: null });
        setThursday({ open: null, close: null });
        setFriday({ open: null, close: null });
        setSaturday({ open: null, close: null });
        setSunday({ open: null, close: null });
        setImages([]);
        setMainColor("");
        setMapScreenshot([]);
        setVideoSrc("");
        setVideoFile();
      },
    },
  );

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleMapsUrlChange = (event) => {
    setMapsUrl(event.target.value);
  };

  const handleRatingChanged = (event, newRating) => {
    setRating(newRating);
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(sliderLabelFormat(newValue));
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccess(false);
    navigate(-1);
  };

  const handleActiveChange = (event) => {
    if (!event.target.checked) setPosition("");
    setActive(event.target.checked);
  };

  const handlePromotedChange = (event) => {
    setIsPromoted(event.target.checked);
  };

  const handleVideoFirstChanged = (event) => {
    if ((videoSrc && event.target.checked) || !event.target.checked)
      setShowVideoFirst(event.target.checked);
  };

  function sliderValueText(value) {
    return `${value}€`;
  }

  function sliderLabelFormat(value) {
    return "€".repeat(value);
  }

  const navigate = useNavigate(); // Initialize useNavigate

  const handleDiscard = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: "linear-gradient(135deg, #d4ebf2, #f2f2f2)",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "90%",
          margin: "0 auto",
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stack
          spacing={3}
          alignItems="center"
          marginTop={4}
          marginBottom={20}
          paddingInline={4}
        >
          <img src={logo} alt="logo" style={{ width: 200 }} />
          <Typography
            variant="h2"
            align="center"
            color="#1EABF1"
            fontSize={30}
            fontFamily="Trueno Light"
            sx={{ paddingBottom: 2 }}
          >
            Upload your new Place
          </Typography>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            width="80%"
            sx={{ marginBottom: 2 }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ opacity: 0.6 }}
              onClick={handleDiscard}
            >
              Cancel Creation
            </Button>
          </Stack>
          <Divider
            sx={{
              width: "85%",
              backgroundColor: "rgba(30, 171, 241, 0.2)",
              height: "1px",
            }}
          />
          <Stack
            spacing={13}
            direction="row"
            justifyContent="center"
            sx={{ paddingTop: 5 }}
          >
            <Stack spacing={3} direction="column" alignItems="left">
              <Typography
                variant="h1"
                align="center"
                fontSize={22}
                fontFamily="Trueno Light"
              >
                1. Main Info
              </Typography>
              <StyledTextField
                label="Place Name"
                placeholder="Enter Place name"
                onChange={handleNameChange}
                value={name}
                fullWidth
                inputProps={{ maxLength: 45 }}
              />
              <StyledSelect
                labelId="select-description"
                value={description}
                onChange={handleDescriptionChange}
                fullWidth
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "") {
                    return <em>Chose Place description</em>;
                  }
                  return selected;
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {descriptions.map((desc) => (
                  <MenuItem value={desc.id} key={desc.id}>
                    {desc.text}
                  </MenuItem>
                ))}
              </StyledSelect>
              <StyledTextField
                label="Place Location"
                placeholder="Enter Place location"
                onChange={handleLocationChange}
                value={location}
                fullWidth
                inputProps={{ maxLength: 45 }}
              />
              <StyledTextField
                label="Google Maps Link"
                placeholder="Enter Google Maps link"
                onChange={handleMapsUrlChange}
                value={mapsUrl}
                fullWidth
              />
              <PhoneInput
                className="phone-input" // Apply the custom class directly
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
                defaultCountry="GR"
                international
                countryCallingCodeEditable={false}
              />

              <Box
                sx={{
                  border: "1px solid #1EABF1", // Border color
                  borderRadius: "4px", // Rounded corners
                  padding: 2, // Padding inside the container
                  width: "100%", // Full width of the parent container
                  maxWidth: 600, // Max width of the container
                  boxShadow: 1, // Optional shadow for better visual effect
                }}
              >
                <Typography
                  variant="h1"
                  align="left"
                  fontSize={20}
                  fontFamily="Trueno Light"
                  sx={{
                    marginBottom: 2,
                    marginLeft: 2,
                    fontWeight: "bold",
                    color: "#1EABF1",
                  }}
                >
                  Admin Only
                </Typography>

                <StyledSelect
                  fullWidth
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>Position in the App</em>;
                    }
                    return selected;
                  }}
                  value={position}
                  variant="outlined"
                  onChange={handlePositionChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {active ? (
                    positions.isLoading ? (
                      <CircularProgress sx={{ color: "#1EABF1" }} />
                    ) : positions.isError ? (
                      <h2>{positions.error.message}</h2>
                    ) : (
                      positions.data.map((pos) => (
                        <MenuItem value={pos} key={pos}>
                          {pos}
                        </MenuItem>
                      ))
                    )
                  ) : null}
                </StyledSelect>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 2, // Space between Select and Slider
                  }}
                >
                  <Slider
                    aria-label="Custom marks"
                    value={priceRange.length}
                    getAriaValueText={sliderValueText}
                    onChange={handlePriceChange}
                    step={null}
                    sx={{
                      color: priceRange ? "#1EABF1" : "#DBDBDB",
                      width: "80%",
                      marginLeft: 2,
                    }}
                    valueLabelDisplay="auto"
                    valueLabelFormat={priceRange}
                    marks={marks}
                    min={1}
                    max={4}
                  />
                  <IconButton
                    aria-label="delete"
                    onClick={() => setPriceRange("")}
                    style={{
                      maxWidth: "40px",
                      maxHeight: "40px",
                      minWidth: "40px",
                      minHeight: "40px",
                      alignSelf: "center",
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>

                <MyRating
                  handleRatingChanged={handleRatingChanged}
                  rating={rating}
                  setRating={setRating}
                  sx={{ marginTop: 2 }}
                />

                <FormControlLabel
                  control={
                    <Switch checked={active} onChange={handleActiveChange} />
                  }
                  label="Active Place"
                  sx={{
                    marginTop: 1,
                    fontFamily: "Trueno Light",
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "Trueno Light",
                    },
                  }}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={isPromoted}
                      onChange={handlePromotedChange}
                    />
                  }
                  label="Promoted Place"
                  sx={{
                    marginTop: 1,
                    fontFamily: "Trueno Light",
                    "& .MuiFormControlLabel-label": {
                      fontFamily: "Trueno Light",
                    },
                  }}
                />
              </Box>
            </Stack>
            <Stack
              spacing={4}
              alignItems="center"
              marginTop={4}
              marginBottom={2}
              paddingInline={4}
            >
              <Typography
                variant="h1"
                align="center"
                fontSize={22}
                fontFamily="Trueno Light"
              >
                2. Select your weekly working hours
              </Typography>
              {/* Notes */}
              <Stack
                width={550}
                direction="column"
                alignItems="flex-start"
                spacing={1}
              >
                <ul
                  style={{ paddingLeft: 20, listStyleType: "disc", margin: 0 }}
                >
                  <li>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#555",
                        fontFamily: "Trueno Light",
                      }}
                    >
                      You can only select times in increments of 5 minutes.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#555",
                        fontFamily: "Trueno Light",
                      }}
                    >
                      In case of non-operating days, leave hours empty.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#555",
                        fontFamily: "Trueno Light",
                      }}
                    >
                      In case of one day's working hours extending to the next
                      day, fill in the closing time of the next day in the
                      closing time field of the current.
                    </Typography>
                  </li>
                </ul>
              </Stack>
              {/* TimeRangePicker stack */}
              <Stack
                spacing={2.5}
                direction="column"
                alignItems="center"
                width={600}
              >
                <Stack
                  direction="row"
                  alignItems="left"
                  spacing={2}
                  width="90%"
                  justifyContent="flex-start"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={applyToAllDays}
                        onChange={handleToggleChange}
                      />
                    }
                    label="Apply Monday's hours to all days"
                    sx={{
                      fontSize: 14,
                      textAlign: "left",
                      fontFamily: "Trueno Light",
                      "& .MuiFormControlLabel-label": {
                        fontFamily: "Trueno Light",
                      },
                    }}
                  />
                </Stack>
                <TimeRangePicker
                  value={monday}
                  setValue={setMonday}
                  day="Monday"
                />
                
                <TimeRangePicker
                  value={tuesday}
                  setValue={setTuesday}
                  day="Tuesday"
                />
                <TimeRangePicker
                  value={wednesday}
                  setValue={setWednesday}
                  day="Wednesday"
                />
                <TimeRangePicker
                  value={thursday}
                  setValue={setThursday}
                  day="Thursday"
                />
                <TimeRangePicker
                  value={friday}
                  setValue={setFriday}
                  day="Friday"
                />
                <TimeRangePicker
                  value={saturday}
                  setValue={setSaturday}
                  day="Saturday"
                />
                <TimeRangePicker
                  value={sunday}
                  setValue={setSunday}
                  day="Sunday"
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: "100%", padding: 2, boxSizing: "border-box" }} // Full width and padding
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: 2,
                padding: 2,
              }}
            >
              {/* Image Selector Container */}
              <Box flex={1}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    minHeight: "400px",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontFamily="Trueno Light"
                    sx={{ marginBottom: 1 }}
                  >
                    3. Upload Images
                  </Typography>
                  <Typography
                    sx={{
                      marginBottom: 2,
                      marginX: 4,
                      fontFamily: "Trueno Light",
                      color: "#666",
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    Add up to 5 images in the order you want them to appear on
                    Join, 1-1 Aspect Ratio and up to 2Mb per image.
                    <br />
                    <strong>Supported files: .jpeg, .jpg, .png, .heic</strong>
                  </Typography>
                  <ImageSelector
                    images={images}
                    setImages={setImages}
                    maxNumber={5}
                    description="images"
                  />
                </Paper>
              </Box>

              {/* Map Screenshot Container */}
              <Box flex={1}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%", // Full height of the container
                    minHeight: "300px", // Minimum height to ensure equal height
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontFamily="Trueno Light"
                    sx={{ marginBottom: 1 }}
                  >
                    4. Upload Map Screenshot
                  </Typography>
                  <Typography
                    sx={{
                      marginBottom: 2,
                      marginX: 4,
                      fontFamily: "Trueno Light",
                      color: "#666",
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    Add one screenshot taken from Google Maps, scale: 2000
                    feet/500 feet, up to 2 MB, trimmed and clean from other
                    elements, showing your location about one third from the
                    top, centered.
                    <br />
                    <strong>Supported files: .jpeg, .jpg, .png, .heic</strong>
                  </Typography>
                  <ImageSelector
                    images={mapScreenshot}
                    setImages={setMapScreenshot}
                    maxNumber={1}
                    description="map"
                  />
                </Paper>
              </Box>

              {/* Video Selector Container */}
              <Box flex={1}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%", // Full height of the container
                    minHeight: "300px", // Minimum height to ensure equal height
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      variant="h6"
                      fontFamily="Trueno Light"
                      sx={{ marginBottom: 1, textAlign: "center" }}
                    >
                      5. Upload One Video{" "}
                      <span style={{ fontSize: "0.65em" }}>(optional)</span>
                    </Typography>

                    <Typography
                      sx={{
                        marginBottom: 2,
                        marginX: 4,
                        fontFamily: "Trueno Light",
                        color: "#666",
                        textAlign: "center",
                        fontSize: 14,
                      }}
                    >
                      Add one video, 1-1 Aspect Ratio and up to 10Mb. The video
                      will show before or after the photos.
                      <br />
                      <strong>Supported files: .mov, .mp4</strong>
                    </Typography>
                    <VideoSelector
                      width={400}
                      height={300}
                      source={videoSrc}
                      setSource={setVideoSrc}
                      setVideoFile={setVideoFile}
                      beforeDelete={beforeDelete}
                    />
                  </Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showVideoFirst}
                        onChange={handleVideoFirstChanged}
                      />
                    }
                    label="Show video before pictures?"
                    sx={{
                      fontFamily: "Trueno Light",
                      "& .MuiFormControlLabel-label": {
                        fontFamily: "Trueno Light",
                      },
                    }}
                  />
                </Paper>
              </Box>
            </Box>
          </Stack>
          <Divider
            sx={{
              width: "85%",
              backgroundColor: "rgba(30, 171, 241, 0.2)",
              height: "1px",
            }}
          />
          {addPlace.isLoading ? (
            <CircularProgress sx={{ color: "#1EABF1" }} />
          ) : (
            <StyledButton
              variant="contained"
              size="large"
              width="30%"
              disabled={
                !name ||
                !location ||
                !description ||
                !mapsUrl ||
                !images.length ||
                !mapScreenshot.length ||
                (!Object.values(monday).every((x) => x !== null) &&
                  !Object.values(tuesday).every((x) => x !== null) &&
                  !Object.values(wednesday).every((x) => x !== null) &&
                  !Object.values(thursday).every((x) => x !== null) &&
                  !Object.values(friday).every((x) => x !== null) &&
                  !Object.values(saturday).every((x) => x !== null) &&
                  !Object.values(sunday).every((x) => x !== null))
              }
              onClick={() => addPlace.mutate()}
              myColor="#1EABF1"
              hoverColor="#187DDA"
            >
              Create Place on Join
            </StyledButton>
          )}
          <Snackbar
            open={addPlace.isError}
            autoHideDuration={2000}
            onClose={() => addPlace.reset()}
          >
            <Alert
              onClose={() => addPlace.reset()}
              severity="error"
              sx={{ width: "100%" }}
            >
              An error occured!
            </Alert>
          </Snackbar>
          <Snackbar
            open={showSuccess}
            autoHideDuration={2000}
            onClose={handleCloseSuccess}
          >
            <Alert
              onClose={handleCloseSuccess}
              severity="success"
              sx={{ width: "100%" }}
            >
              Place added successfully!
            </Alert>
          </Snackbar>
          <Typography
            variant="h1"
            align="center"
            fontSize={14}
            fontFamily="Trueno Light"
            fontStyle="italic"
          >
            You may edit this Place's details at all times
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
