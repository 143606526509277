import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

export const usePlacesData = (onSuccess, onError, token) => {
  const fetchPlaces = () => {
    const headers = {
      Accept: "*/*",
      token: token,
    };

    return axios.get(
      `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/places/get_all_places`,
      {
        headers,
      },
    );
  };

  return useQuery("places-get", fetchPlaces, {
    retry: 1,
    refetchOnWindowFocus: false,
    enabled: true,
    onSuccess: onSuccess("places"),
    onError: onError("places"),
    select: (data) => data.data,
  });
};

export const usePlaceData = (onSuccess, onError, token, placeId) => {
  const queryClient = useQueryClient();

  const fetchPlace = () => {
    const headers = {
      Accept: "*/*",
      token: token,
    };

    console.log("fetching");

    return axios.get(
      `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/places/get_place/${placeId}`,
      {
        headers,
      },
    );
  };

  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["places-get", placeId],
    queryFn: () => fetchPlace(),
    select: (data) => data.data,
    initialData: () => {
      const data = queryClient.getQueryData(["places-get"])?.data;
      return data
        ? { data: data.find((place) => place.placeId === placeId) }
        : undefined;
    },
  });
};

export const usePositions = (mode, token) => {
  const fetchPositions = () => {
    const headers = {
      Accept: "*/*",
      token: token,
    };

    console.log("fetching");

    return axios.get(
      `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/${mode}/get_available_positions`,
      {
        headers,
      },
    );
  };

  const onSuccess = (data) => {
    console.log(`${mode} positions fetched`, data);
  };

  const onError = (error) => {
    console.log(`${mode} positions error`, error);
  };

  return useQuery(["get-positions", mode], fetchPositions, {
    retry: 1,
    refetchOnWindowFocus: false,
    enabled: true,
    onError,
    onSuccess,
    select: (data) => data.data,
  });
};

export const useBigEventsData = (onSuccess, onError, token) => {
  const fetchBigEvents = () => {
    const headers = {
      Accept: "*/*",
      token: token,
    };

    return axios.get(
      `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/big_events/get_all_big_events`,
      {
        headers,
      },
    );
  };

  return useQuery("big_events-get", fetchBigEvents, {
    retry: 1,
    refetchOnWindowFocus: false,
    enabled: true,
    onSuccess: onSuccess("big_events"),
    onError: onError("big_events"),
    select: (data) => data.data,
  });
};

export const useBigEventData = (onSuccess, onError, token, bigEventId) => {
  const queryClient = useQueryClient();

  const fetchBigEvent = () => {
    const headers = {
      Accept: "*/*",
      token: token,
    };

    console.log("fetching");

    return axios.get(
      `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/big_events/get_big_event/${bigEventId}`,
      {
        headers,
      },
    );
  };

  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["big_events-get", bigEventId],
    queryFn: () => fetchBigEvent(),
    select: (data) => data.data,
    initialData: () => {
      const data = queryClient.getQueryData(["big_events-get"])?.data;
      return data
        ? { data: data.find((event) => event.bigEventId === bigEventId) }
        : undefined;
    },
  });
};

export const usePlaceFile = (onSuccess, onError, token, fileName) => {
  const fetchFile = () => {
    const headers = {
      Accept: "*/*",
      token: token,
    };

    return axios.get(
      `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/places/file/${fileName}`,
      {
        responseType: "arraybuffer",
        headers,
      },
    );
  };

  return useQuery(["places-get", fileName], fetchFile, {
    retry: 1,
    refetchOnWindowFocus: false,
    enabled: true,
    onSuccess: onSuccess("files"),
    onError: onError("files"),
    select: (data) => {
      return data.data;
    },
  });
};

export const useBigEventFile = (onSuccess, onError, token, fileName) => {
  const fetchFile = () => {
    const headers = {
      Accept: "*/*",
      token: token,
    };

    return axios.get(
      `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_ADMIN_PORT}/big_events/file/${fileName}`,
      {
        responseType: "arraybuffer",
        headers,
      },
    );
  };

  return useQuery(["big_events-get", fileName], fetchFile, {
    retry: 1,
    refetchOnWindowFocus: false,
    enabled: true,
    onSuccess: onSuccess("files"),
    onError: onError("files"),
    select: (data) => {
      return data.data;
    },
  });
};
