import React from "react";
import Box from "@mui/material/Box";
//import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";

export default function AppBarHeader() {
  return (
    <Container maxWidth={false} disableGutters>
      <Box sx={{ bgcolor: "#808080", flexGrow: 1, height: 5, maxHeight: 5 }} />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          bgcolor: "black",
          flexGrow: 1,
          color: "#B2B2B2",
          height: 90,
          maxHeight: 90,
          fontFamily: "OpenSans SemiBold",
        }}
      >
        © 2024 Join Social App. Version 3.2.6
      </Box>
    </Container>
  );
}
